import React from 'react'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const termsStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 32px);
  margin-right: auto;
  margin-left: auto;
  padding-top: 1px;
  padding-right: 24px;
  padding-left: 24px;

  @media screen and (${STYLE.MEDIA.PC}) {
    margin-top: 80px;
    padding-right: 32px;
    padding-left: 32px;
  }

  & > .content {
    padding: 28px 32px 20px;
    border-radius: 16px;
    background: var(--color-white);
    line-height: 1.75;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 0;
      padding: 56px 80px 40px;
    }
  }

  & > .content > .header {
    display: flex;
    justify-content: center;
    font-size: ${rem(20)};
    font-weight: bold;
  }

  & > .content > .subheader {
    margin-top: 28px;
    font-size: ${rem(16)};
    font-weight: bold;
  }

  & > .content > .lead {
    margin-top: 28px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 56px;
    }
  }

  & > .content > .items {
    margin-top: 8px;
  }

  & > .content > .items > .item {
    margin-top: 8px;
  }

  & > .content > .items > .item > .items {
    margin-top: 8px;
    margin-left: 1em;
  }

  & > .content > .items > .item > .items > .item {
    margin-top: 8px;
  }

  & > .content > .footer {
    margin-top: 80px;
  }
`

const breadcrumb = [
  {
    label: '利用規約',
  },
]

const seo = {
  title: '利用規約',
}

const TermsPage = () => {
  /* eslint-disable no-irregular-whitespace */
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={termsStyle}>
        <div className="content">
          <h1 className="header">ASHIATO 利用規約</h1>
          <p className="lead">
            ASHIATO利用規約（以下「本規約」といいます。）は、エン・ジャパン株式会社（以下「当社」といいます。）が提供する、ASHIATO（利用者が採用候補者の採用選考または採用後の人材育成または社内人事に活用することができる情報を当社が入手し、加工又は分析してレポートを提供するサービス（以下「本サービス」といいます。）についての利用条件を定めるものです。
          </p>
          <h2 className="subheader">第1条 (本規約の趣旨・目的)</h2>
          <ol className="items">
            <li className="item">
              1．本規約は、当社が本サービスの利用者（以下、「利用者」といいます。）に対し、本サービスを提供するにあたり、その条件を明示し、当社と利用者との間の権利義務関係を定めることを目的とします。
            </li>
            <li className="item">
              2．当社が、別途申込書等により、本サービスに関する個別規定や追加規定を提示した場合、それらは本規約の一部を構成するものとし、個別規定、追加規定が本規約と抵触する場合は、当該個別規定、追加規定が優先されるものとします。
            </li>
          </ol>
          <h2 className="subheader">第2条 (申込)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、本規約を遵守することに同意し、申込書の提出その他当社の定める方法に従い、当社の求める情報を当社に提供することにより、申込みを行うものとします。
            </li>
            <li className="item">
              2．当社は、前項の申込みがあった場合、当社の定める審査基準に従って審査することがあり、当社が審査基準に適合しないと判断した場合には、申込を拒否することができるものとします。なお、当社は、当該審査基準や申込みを拒否した理由につき、回答する義務を負うものではありません。
            </li>
            <li className="item">
              3．当社は、利用者の申込みを承諾する場合、当該利用者に対してID
              およびパスワードを発行し、アカウントを付与し、本規約、申込書記載事項、個別規定及び追加規定に基づく契約（以下「本契約」といいます。）が成立するものとします。
            </li>
          </ol>
          <h2 className="subheader">第3条 (利用者による登録情報等の提供)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、本サービスの利用に際して登録する自己に関する情報や、その他本サービスの利用にあたり提供する情報
              (以下「登録情報」といいます。)
              の登録にあたっては、真実かつ正確な情報を、個人情報保護法を含む法令、規則等に違反しないように提供するものとします。また、登録情報に誤りがあった場合または変更が生じた場合、利用者は、自己の責任において、速やかに登録情報を修正または変更するものとします。
            </li>
            <li className="item">
              2．当社は、利用者が登録した登録情報を法令その他の諸規範に照らし適切に取り扱うとともに、登録情報を前提として本サービスを提供します。これらの情報の内容に虚偽、誤り、記載漏れまたは変更未了があったことにより利用者に損害が生じたとしても、当社は一切責任を負いません。
            </li>
            <li className="item">
              3．当社は、登録情報のうち、利用者が任意に設定した本サービスにおける推薦者（以下、「推薦者」といいます。）への質問項目及びそれに対する推薦者の回答を、ある採用候補者（以下、「採用候補者」といいます。）が転職を検討している求人企業に対し、本サービスの運営上合理的に必要な範囲で提供することができるものとし、利用者は予めこれに同意するものとします。
            </li>
          </ol>
          <h2 className="subheader">第4条 (アカウントの管理)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、自己の責任においてアカウントを管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
            </li>
            <li className="item">
              2．当社は、 ID
              およびパスワードの一致を確認した場合、当該アカウントを保有する者として登録された利用者が本サービスを利用したものとみなします。
            </li>
            <li className="item">
              3．アカウントの管理不十分、使用上の過誤、第三者の使用等による損害の責任は利用者が負うものとし、当社は一切の責任を負いません。
            </li>
            <li className="item">
              4．利用者は、アカウントが盗用されまたは第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
            </li>
          </ol>
          <h2 className="subheader">第５条 (本サービスの利用)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、本サービスの利用により取得した推薦者及び採用候補者の情報について、採用選考または採用後の人材育成または社内人事に活用する目的以外に利用しないものとします。
            </li>
            <li className="item">
              2．利用者は、本サービスを当社から提供された状態で利用するものとし、本サービスの複製、修正、変更、改変または翻案を行うことはできません。
            </li>
            <li className="item">
              3．利用者は、本サービスを、直接的または間接的にかかわらず、第三者に対する業務提供その他これに類似する用途に用いてはならないものとします。
            </li>
            <li className="item">
              4．本サービスの提供を受けるために必要なコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持は、利用者の費用と責任において行うものとします。
            </li>
          </ol>
          <h2 className="subheader">第６条 (本サービスの利用料等)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、当社に対して、申込書に記載された利用料を支払うものとします。なお、支払いに要する費用は利用者の負担とします。
            </li>
            <li className="item">2．支払方法・条件等については、申込書の記載された内容に従うものとします。</li>
            <li className="item">
              3．利用期間中の利用プランの変更は認められないものとします。ただし、利用期間満了前の30日前までに、当社に対して利用プランを変更する旨の申し出を行った場合、変更後の利用プランにて利用期間を更新するものとします。
            </li>
            <li className="item">
              4．期間の途中において本契約が終了した場合、利用者は、当社に対し、提供途中のサービスが存在する場合においても、利用料の返還を求めることはできないものとします。
            </li>
          </ol>
          <h2 className="subheader">第７条 (情報の利用)</h2>
          <ol className="items">
            <li className="item">
              当社は、利用者が当社に提供した情報（個人情報を含む。）を、本サービスの提供および運用、サービス内容の改良および向上、本サービスの利用状況の把握等の目的のために利用し、またはコンサルティング、新サービスの開発その他の目的のために利用することができるものとします。
            </li>
          </ol>
          <h2 className="subheader">第８条 (禁止行為)</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。また、利用者以外の第三者に、以下の各号のいずれかに該当する行為をさせてはなりません。
              <ol className="items">
                <li className="item">（1）法令に違反又は犯罪に該当し、またはこれらの恐れがあると当社が判断する行為</li>
                <li className="item">（2）当社、採用候補者、推薦者その他の第三者に対して虚偽の情報を提供する行為</li>
                <li className="item">
                  （3）本サービスの利用中または、本サービスの利用期間満了後1年以内に、本サービスと同様のサービスを立ち上げる行為
                </li>
                <li className="item">
                  （4）本サービスを利用した採用活動の遂行途中で、合理的理由なく当該活動を放棄する行為その他採用候補者または推薦者に対する不誠実な行為
                </li>
                <li className="item">
                  （5）当社、採用候補者、推薦者その他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利若しくは利益を侵害する行為またはそのおそれのある行為
                </li>
                <li className="item">
                  （6）採用候補者、推薦者その他の第三者の要配慮個人情報（個人情報の保護に関する法律第2条第3項に定義されるものを意味します。）を、本サービスを利用して取得する行為。ただし、業務との合理的な関連性がある範囲で、かつ、本人の同意を得て取得した場合を除きます。
                </li>
                <li className="item">（7）本サービスの誤作動を誘引する行為</li>
                <li className="item">（8）本サービスまたは当社サーバー等に過度の負担をかける行為</li>
                <li className="item">（9）本サービスに接続しているシステムに権限なく不正にアクセスする行為</li>
                <li className="item">
                  （10）
                  本サービスに対するリバースエンジニアリング、逆コンパイル、または逆アセンブラ等、本サービスを解析しようと試みる行為
                </li>
                <li className="item">（11）当社または他の利用者その他の第三者に成りすます行為</li>
                <li className="item">
                  （12）他の利用者の ID 若しくはパスワードを利用する行為または第三者に対して自身の ID
                  若しくはパスワードを伝達する行為
                </li>
                <li className="item">（13）本サービスの正常な運営を妨害する行為</li>
                <li className="item">（14）本契約のいずれかの条項に違反する行為</li>
                <li className="item">（15）当社からの問合せ等の回答を求める連絡に対して30日間以上応答しない行為</li>
                <li className="item">（16）前各号の行為を直接または間接に惹起しまたは是正しない行為</li>
                <li className="item">（17）前各号に準ずる行為又はその他当社が不適切と判断する行為</li>
              </ol>
            </li>
            <li className="item">
              2．利用者が、前項のいずれかに違反した場合、当社は、直ちに本サービスの利用停止又は本契約を解除することができるものとします。
            </li>
            <li className="item">
              3．前項に基づく、本サービスの利用停止は、当社の債務不履行を構成せず、利用料の発生を妨げることなく、当社は何らの責任も負担しないものとします。
            </li>
          </ol>
          <h2 className="subheader">第９条 (本契約の解除等)</h2>
          <ol className="items">
            <li className="item">
              1．当社は、利用者が以下の各号のいずれかに該当しまたは該当すると判断した場合は、事前に通知することなく、本サービスの全部または一部の利用を一時停止しまたは本契約を解除することができます。なお、当社は、上記判断に関する理由を開示する義務は負いません。
              <ol className="items">
                <li className="item">
                  （1）本規約、申込書記載事項、個別規定又は追加規定の条項のいずれかに違反した場合
                </li>
                <li className="item">（2）利用料の支払の全部または一部を遅延した場合</li>
                <li className="item">（3）監督官庁から営業停止または取消等の処分等を受けた場合</li>
                <li className="item">
                  （4）当社に提供された情報の全部または一部につき虚偽の事実があることが判明した場合
                </li>
                <li className="item">（5）差押、仮差押、仮処分または租税滞納処分を受けた場合</li>
                <li className="item">
                  （6）支払停止若しくは支払不能、または破産手続開始、民事再生、会社更生、清算手続若しくはこれらに類する手続の開始の申立てがあった場合
                </li>
                <li className="item">
                  （7）本サービスの運営、保守管理上必要である場合その他前各号に準じる事由がある場合
                </li>
              </ol>
            </li>
            <li className="item">
              2．利用者は、業務停止等を受けた後も、本契約上の一切の義務および債務を免れるものではありません。
            </li>
            <li className="item">
              3．当社は、利用者が前条第1項各号または本条第1項各号の一に該当し、または該当するおそれがあると当社が判断した場合には、利用者に対し、違反行為の中止、送信した情報の自発的な削除・訂正その他違反状態の是正に必要な行為を求めることがあります。
            </li>
            <li className="item">
              4．本条各号に定める事由に該当したときは、利用者は、当社に対するすべての債務につき、期限の利益を喪失し、直ちに当該債務を履行する義務を負うものとします。
            </li>
          </ol>
          <h2 className="subheader">第10条（反社会的勢力の排除）</h2>
          <ol className="items">
            <li className="item">
              1．利用者は、本サービスを利用するにあたり、当社に対して次に定める事項を表明し、保証しなければならないものとします。
              <ol className="items">
                <li className="item">
                  （1）申込者及び利用者が反社会的勢力（「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団及びその関係団体その他の反社会的団体または勢力をいうものとします）ではないこと
                </li>
                <li className="item">（2）反社会的勢力を利用しないこと及びこれに準ずる行為をしないこと</li>
                <li className="item">（3）反社会的勢力に資金提供を行わないこと及びこれに準ずる行為をしないこと</li>
                <li className="item">
                  （4）反社会的勢力を名乗る等して当社の名誉等の毀損、業務妨害、不当要求行為、またはこれに準ずる行為をしないこと
                </li>
                <li className="item">（5）利用者の役職員が反社会的勢力の構成員ではないこと</li>
              </ol>
            </li>
            <li className="item">
              2．利用者は、前項各号の定めに対する違反を発見した場合は、直ちに当社にその事実を報告しなければならないものとします。
            </li>
            <li className="item">
              3．利用者が本条第１項各号に違反した場合または違反していると当社が合理的に判断できる場合、当社は催告を要することなく、直ちに本サービス及び当社が別途利用者と締結している契約の全部または一部を解除することができるものとします。
            </li>
            <li className="item">4．利用者は、本条違反により生じた損害について、当社に請求しないものとします。</li>
            <li className="item">
              5．本条に基づく契約解除に伴い当社に損害が発生した場合、当社は利用者に対して当該損害の賠償請求を行うことができるものとします。
            </li>
          </ol>
          <h2 className="subheader">第11条 (損害賠償等)</h2>
          <ol className="items">
            <li className="item">
              1．本契約の違反、その他本サービスの利用に起因し、当社に直接または間接の損害が生じた場合、利用者は当社に対して、その損害を賠償するものとします。
            </li>
            <li className="item">
              2．当社は、本契約の違反行為、その他本サービスの利用に起因して利用者が被った損害について、当該損害の発生に関して当社に故意または重過失がある場合にのみ責任を負うものとします。
            </li>
          </ol>
          <h2 className="subheader">第12条 (秘密保持)</h2>
          <ol className="items">
            <li className="item">
              1．当社および利用者は、相手方の事前の書面による承諾もしくは本規約による別段の定めがある場合を除き、本サービスおよび本サービスに関連して相手方が開示または提供した情報
              (個人情報を含みます。以下「秘密情報」といいます。)を秘密に取り扱い、第三者に開示または提供しないものとします。ただし、相手方の書面の同意を得た場合、または法令により第三者への開示若しくは提供を強制され、必要最小限度の範囲で開示または提供する場合を除きます。
            </li>
            <li className="item">
              2．前項にかかわらず、次の各号のいずれかに該当する情報は、前項における秘密情報から除くものとします。
              <ol className="items">
                <li className="item">
                  （1）開示若しくは提供の時点で既に公知のもの、または開示若しくは提供後秘密情報を受領した受領者の責によらずして公知となったもの
                </li>
                <li className="item">（2）受領者が第三者から秘密保持義務を負うことなく正当に入手したもの</li>
                <li className="item">（3）開示または提供の時点で受領者が既に保有しているもの</li>
                <li className="item">（4）開示を受けた後に、開示された事項とは関係なく、独自に開発又は知得したもの</li>
              </ol>
            </li>
            <li className="item">
              3．利用者は、当社から開示または提供された秘密情報の秘密を保持し、本サービスを利用した採用候補者の採用活動を行うため、または当該採用候補者の採用後の人材育成若しくは社内人事に活用するために知る必要のある自己の役員および従業員以外に開示または提供してはならないものとします。
            </li>
            <li className="item">
              4．当社および利用者は、相手方から求められた場合はいつでも、相手方の指示に従い、遅滞なく、第1項の秘密情報および当該情報を記載または記録した書面その他の記録媒体物並びにそのすべての複製物等を返却または廃棄するものとします。
            </li>
          </ol>
          <h2 className="subheader">第13条 (権利帰属等)</h2>
          <ol className="items">
            <li className="item">
              1．本サービス及び本サービス内のすべてのコンテンツ（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）に関する一切の知的財産権は、利用者が本サービスの利用にあたり当社に提供する情報（登録情報を含む、文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）を除き、当社又は当社に利用を許諾した権利者に帰属します。利用者は、本サービスの利用にあたり当社に提供する情報を本契約の目的を遂行するために必要な範囲において当社が無償で利用することを許諾するとともに、かかる利用について当社又は当社の指定する第三者に対して著作者人格権を行使しないものとします。
            </li>
            <li className="item">
              2．本サービス上、商標、ロゴおよびサービスマーク等 (以下、総称して「商標等」といいます。)
              が表示される場合がありますが、当社は、利用者その他の第三者に対し、商標等を譲渡し、またはその利用を許諾するものではありません。
            </li>
            <li className="item">
              3．本サービスの利用許諾は、本契約に明示的に規定される場合を除き、本サービスに関する当社または当社に利用を許諾した権利者の知的財産権に関し、いかなる権利も許諾するものではありません。利用者は、本サービスが予定している利用態様を超えて、本サービスを利用することはできません。
            </li>
          </ol>
          <h2 className="subheader">第14条 (中途解約)</h2>
          <ol className="items">
            <li className="item">
              1．当社および利用者は、本契約の有効期間中でも、解約希望日の30日前までに当社の定める方法にて相手方に通知することにより、本サービスを終了することができます。なお、利用者からの通知により本サービスの有効期間中に、本サービスを終了する場合は、本サービスの履行状況にかかわらず、当社に対して、未払いの本サービスの利用料全額を直ちに支払うものとします。
            </li>
            <li className="item">
              2．当社は、前項に基づく本サービスの終了により利用者に生じた損害について、一切の責任を負うものではありません。
            </li>
            <li className="item">
              3．利用者は、理由の如何にかかわらず、本サービスの終了時点で、本サービスを利用することができなくなります。
            </li>
          </ol>
          <h2 className="subheader">第15条 (本サービスの変更・中断・終了等)</h2>
          <ol className="items">
            <li className="item">
              1．当社は、利用者に対して事前の通知を行い、本サービスの内容の全部または一部を変更または追加することができるものとします。
            </li>
            <li className="item">
              2．当社は、事前に、当社ウェブサイト上への掲示その他の当社が適当と判断する方法で利用者に通知することにより、本サービスの全部または一部の提供を終了することができるものとします。
            </li>
            <li className="item">
              3．当社は、次の各号の事由が生じた場合には、利用者に事前に通知することなく、本サービスの全部または一部を一時的に中断することができるものとします。
              <ol className="items">
                <li className="item">
                  （1）本サービス用の通信機器設備等に関わるメンテナンスや修理、その他予期せぬ要因でシステムに負荷が集中した場合
                </li>
                <li className="item">（2）利用者のセキュリティを確保する必要が生じた場合</li>
                <li className="item">（3）電気通信事業者の役務が提供されない場合</li>
                <li className="item">
                  （4）地震、水害等の天災、火災、停電、その他の不慮の事故または戦争、紛争、動乱、暴動、労働争議等により本サービスの提供が困難な場合
                </li>
                <li className="item">（5）法令またはこれに基づく措置により本サービスの運営が困難となった場合</li>
                <li className="item">（6）その他前各号に準じ当社が必要と判断した場合</li>
              </ol>
            </li>
            <li className="item">
              4．当社は、前項各号に基づき行った措置により、利用者に生じた損害について当該不利益または損害の発生について、一切の責任を負わないものとします。
            </li>
          </ol>
          <h2 className="subheader">第16条 (免責)</h2>
          <ol className="items">
            <li className="item">
              1．当社は、全ての採用候補者について推薦者からの回答が得られること、採用候補者または推薦者が回答した情報が正確であることについて、保証するものではありません。
            </li>
            <li className="item">
              2．当社は、本サービスおよび本サービスを通じて提供される情報、その他一切の情報につき、利用者の特定の目的への適合性、商用的価値、正確性、有用性、完全性、適法性、利用者に適用のある団体の内部規則等への適合性、およびセキュリティ上の欠陥、エラー、バグまたは不具合が存しないこと、ならびに第三者の権利を侵害しないことについて、保証するものではありません。
            </li>
            <li className="item">
              3．本サービスに関し、利用者と第三者との間で紛争が生じた場合、利用者は、直ちにその旨を当社に通知するとともに、自己の責任と費用においてこれを解決するものとし、当社はこれに一切関与せず、何ら責任を負わないものとします。
            </li>
          </ol>
          <h2 className="subheader">第17条 (連絡・通知)</h2>
          <ol className="items">
            <li className="item">
              1．本規約の変更に関する通知、その他本サービスに関する当社から利用者への連絡は、当社ウェブサイト内の適宜の場所への掲示、電子メールの送信等、当社が適当と判断する方法により行うものとします。
            </li>
            <li className="item">
              2．本サービスに関する問い合わせ、その他本契約に基づく利用者から当社に対する連絡または通知は、当社ウェブサイト内に設置するお問い合わせフォームへの送信、その他当社が指定する方法により行うものとします。
            </li>
          </ol>
          <h2 className="subheader">第18条 (契約上の地位の譲渡等)</h2>
          <ol className="items">
            <li className="item">
              　当社および利用者は、相手方の書面による事前の承諾なく、本サービス上の地位または本契約に基づく権利義務の全部または一部を、第三者に譲渡
              (合併、会社分割等による包括承継を含みます。) し、または担保の目的に供することはできません。
            </li>
          </ol>
          <h2 className="subheader">第19条 (事業譲渡)</h2>
          <ol className="items">
            <li className="item">
              1．当社が本サービスにかかる事業の全部または一部を他社に譲渡等する場合、当社は、当該事業譲渡等に伴い本契約上の地位、権利および義務並びに会員情報等を当該事業譲渡等の譲受人に譲渡できるものとし、利用者は、かかる譲渡等につき予め同意したものとみなします。
            </li>
            <li className="item">
              2．本条にいう事業譲渡等には、当社が消滅会社または分割会社となる合併または会社分割等による包括承継を含むものとします。
            </li>
          </ol>
          <h2 className="subheader">第20条（本規約の変更）</h2>
          <ol className="items">
            <li className="item">
              1．当社は、本サービス上に掲載する方法等により事前に申込者へ通知を行い、本規約を変更することができるものとします。
            </li>
            <li className="item">2．本規約の変更は、前項の通知において指定した日付より効力を生じるものとします。</li>
            <li className="item">
              3．当社は、利用料等の重要事項を変更する場合は、前項の指定した日付までに相応の期間をもって、通知を行なうよう努めるものとします。
            </li>
            <li className="item">
              4．利用者が、本条第1項の通知において指定した日付までに異議申立てを行わない場合、本規約の変更に同意したものとみなします。
            </li>
          </ol>
          <h2 className="subheader">第21条 (有効期間)</h2>
          <ol className="items">
            <li className="item">
              　本サービスの有効期間は、申込書に記載された利用期間とします。ただし、当社または利用者のいずれかから、有効期間満了の30日前までに書面または電磁的方法等により反対の意思表示がされない限り、本契約は同一の条件で更新されるものとし、その後も同様とします。
            </li>
          </ol>
          <h2 className="subheader">第22条 (契約終了時の情報の処理等)</h2>
          <ol className="items">
            <li className="item">
              1．本契約が期間満了その他の理由により終了した場合、利用者は本サービスを一切利用できないものとし、当社から提供された一切の情報等を直ちに当社に返還または廃棄し、当社の求めに応じて、廃棄した旨の証明書を当社に交付するものとします。但し、採用候補者を実際に採用した場合は、当該採用候補者に関する情報を、本サービス終了後も保持できるものとします。
            </li>
            <li className="item">
              2．利用者は、本サービスが終了、解除または解約された場合、本サービスに関連してダウンロード、コピーしたデータ等について、保存することにつき合理的根拠が認められるものを除き、全て廃棄または削除するものとします。
            </li>
          </ol>
          <h2 className="subheader">第23条 (分離可能性)</h2>
          <ol className="items">
            <li className="item">
              1．本契約のいずれかの条項またはその一部が無効等と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、本契約の残りの部分は、引き続き効力を有するものとします。
            </li>
            <li className="item">
              2．本契約のいずれかの条項またはその一部が、特定の利用者との関係で無効等と判断された場合であっても、他の利用者との関係における効力には影響を及ぼさないものとします。
            </li>
          </ol>
          <h2 className="subheader">第24条（存続条項）</h2>
          <ol className="items">
            <li className="item">
              　本契約等の終了にかかわらず、第3条第2項（登録情報の提供）、第4条第3項（アカウントの管理）、第6条第4項（本サービスの利用料等）、第7条（情報の利用）、第8条第3項（禁止行為）、第9条（本契約の解除等）、第10条第４項及び第５項（反社会的勢力の排除）、第11条（損害賠償等）、第12条（秘密保持）、第13条（権利帰属）、第14条第2項（中途解約）、第15条第4項（本サービスの変更・中断・終了等）、第16条（免責）、第17条（連絡・通知）、第23条（分離可能性）、第25条（準拠法及び管轄裁判所）、第26条（協議解決）及び本条（存続条項）は有効に存続する。ただし、第12条（秘密保持）は、本契約終了後3年までに限る。
            </li>
          </ol>
          <h2 className="subheader">第25条 (準拠法および合意管轄)</h2>
          <ol className="items">
            <li className="item">
              　本契約は日本法に準拠するものとし、本サービスに起因しまたは関連する一切の紛争については、その訴額に応じ、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
          <h2 className="subheader">第26条 (協議解決)</h2>
          <ol className="items">
            <li className="item">
              　当社および利用者は、本契約に定めのない事項または、本契約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
            </li>
          </ol>
          <div className="footer">
            <p className="text">2023年1月23日 改訂</p>
            <p className="text">2020年10月15日 新設</p>
          </div>
        </div>
      </main>
    </Layout>
  )
  /* eslint-enable no-irregular-whitespace */
}

export default TermsPage
